<template>
  <div class="gap-4 p-4">
    <Card>
      <template #title> Informations </template>
      <template #subtitle> Saisissez vos informations personelles </template>
      <template #content>
        <form @submit.prevent="nextPage" id="infos">
          <div class="p-field p-fluid">
            <label>Prénom</label>
            <InputText type="text" v-model="firstname" />
          </div>
          <div class="p-field p-fluid">
            <label>Nom</label>
            <InputText type="text" v-model="lastname" />
          </div>
          <hr />
          <div class="p-field p-fluid">
            <label>Adresse email</label>
            <InputText type="email" v-model="email" />
          </div>
        </form>
      </template>
      <template #footer>
        <hr />
        <div class="flex justify-content-end">
          <Button
            label="Suivant"
            class="p-button-sm"
            type="submit"
            form="infos"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup(props, { emit }) {
    const pageIndex = 0;
    const nextPage = () => emit("next-page", { formData, pageIndex });
    const formData = reactive({
      firstname: undefined,
      lastname: undefined,
      email: undefined,
    });

    return { nextPage, ...toRefs(formData) };
  },
};
</script>

<style lang="scss" scoped>
.p-field {
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
